<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '/~/utils/format/date'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useProvider } from '/~/composables/provider'
import { Statement } from '/~/composables/statements/core/Statement'

const props = defineProps<{
  item: Statement
}>()

const router = useRouter()
const { payment, currentFlowType } = useCheckoutReactive()
const loading = ref(false)

async function onPay() {
  if (!props.item.active || props.item.isBlocked) {
    return
  }
  loading.value = true
  currentFlowType.value = FlowType.statement
  await payment.value.initPayment({
    statement: props.item,
  })
  loading.value = false
  const { isBillPaymentsV15Template } = useProvider()

  if (isBillPaymentsV15Template.value) {
    router.push({
      name: 'payments-make',
      params: {
        payingTo: {
          amount: props.item.raw.subtotal,
          payeeId: props.item.raw?.userStatementAccount?.id,
        },
      },
    })
  } else {
    router.push({
      name: 'statement-checkout',
      params: {
        id: props.item.id,
      },
    })
  }
}
</script>

<template>
  <div
    class="my-2.5 flex items-center justify-between rounded-xl border-2 border-solid border-primary-lighten bg-primary-lightest px-[15px] py-2.5"
  >
    <div class="flex flex-row items-center gap-x-[15px] overflow-hidden">
      <payee-icon :payee="item.payee" />
      <div class="overflow-hidden pr-1">
        <span class="text-base font-bold text-eonx-neutral-800">
          {{ item.payeeName }}
        </span>
        <div class="flex flex-col">
          <span class="truncate text-sm font-medium text-eonx-neutral-600">
            Acc No. {{ item.clientNumber }}
          </span>
          <span class="text-sm font-medium text-eonx-neutral-600">
            Due {{ formatDate('daymonthyearnumeric', item.dueDate) }}
          </span>
        </div>
      </div>
    </div>

    <base-button
      look="outlined-color"
      class="!rounded-md px-5 py-2.5"
      :loading="loading"
      @click="onPay"
    >
      Pay now
    </base-button>
  </div>
</template>
